@media screen and (min-width: 600px)  {
  br.mobile { display: none; }
}

@media screen and (max-width: 600px)  {
  br.desktop { display: none; }
}

@media screen and (max-width: 1380px)  {
  .hero-arrows { display: none; }
}
